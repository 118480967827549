<template>
    <div>
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane disabled></el-tab-pane>
                <!-- <el-tab-pane label="Lottery" name="first" style="background-color: black"> -->
                <el-tab-pane :label="$t('luckdraw.lotteryticket')" name="first" style="background-color: #1B1F24">
                    <div style="width: 80%;margin-left: 10%;padding-top: 20px;">
                        <div style="line-height: 60px;">
                            <span style="color: white;font-size: 20px;">{{$t('luckdraw.integral')}}：</span>
                            <span style="color: #13CFDA;font-size: 36px;font-weight: bolder;">{{ integral }}</span>
                        </div>
                        <div style="width: 100%">
                            <div style="background-color: #24292F;color: #C0CCD6;padding: 5px 10px;font-size:0.8em;border-radius: 5px;">
                                <span style="display: flex;flex-direction: row;flex-wrap: wrap;">
                                    {{$t('luckdraw.welcome')}}
                                </span>
                            </div>
                        </div>
                        <div style="width: 100%;height: 100%">
                            <a-row style="width: 100%">
                                <a-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6" v-for="item in 8" :key="item" justify="center" align="middle">
                                    <transition name="el-zoom-in-center">
                                        <div v-show="show" @click="openprize(item)" class="transition-box transition-common"></div>
                                    </transition>
                                </a-col>
                                <a-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6" v-for="(item) in prizepools" :key="item.weight" justify="center" align="middle">
                                    <transition name="el-zoom-in-center">
                                        <div v-show="!show" @click="show = true" class="transition-boxopen transition-common">
                                            <div class="justify-center" style="margin-top: 35%">
                                                <p class="coupontitle">{{ item.name }}</p>
                                            </div>
                                            <div class="justify-center">
                                                <div class="couponinfo">
                                                    <p class="couptxt" v-if="item.isprize == '1'">{{ item.timelength }}</p>
                                                    <p class="couptxt" v-else>{{$t('luckdraw.none')}}</p>
                                                </div>
                                            </div>
                                            <div class="justify-around redemptioncode" v-if="item.isprize == '1'">
                                                <span>
                                                    {{$t('luckdraw.redemptioncode')}}
                                                    <span class="excode">{{ item.excode }}</span>
                                                </span>
                                                <span style="color: #1B1F24;cursor: pointer" @click="copyexcode(item.excode)" @click.stop="copyexcode">{{$t('luckdraw.copy')}}</span>
                                            </div>
                                            <div class="justify-around redemptioncode" v-else>
                                                <span>{{$t('luckdraw.none')}}</span>
                                            </div>
                                        </div>
                                    </transition>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="Prize list" name="second"> -->
                <el-tab-pane :label="$t('luckdraw.prizelist')" name="second">
                    <el-data-table ref="table" v-bind="tableConfig" :columns="columns" style="margin-left: 30px;margin-top: 10px;width: 95%;height: 50vh">
                    </el-data-table>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { toluckdraw, getUserIntegral, exchange } from "@/request/requests";
import { getUserInfo, MessageError, MessageInfo, MessageSuccess, startLoading } from "@/util/util"
export default {
    name: "luckdraw",
    data() {
        const statusOptions = [
            {
                value: "1",
                // label:"not used"
                // label: "사용하지 않았다"
                label: this.$t('luckdraw.notused')
            },
            {
                value: "2",
                // label:"Used"
                // label: "이미 사용 된"
                label: this.$t('luckdraw.used')
            },
            {
                value: "3",
                // label:"Expired"
                // label: "만료"
                label: this.$t('luckdraw.expired')
            },
        ]
        const columns = [
            // {prop: 'id',label: 'Prize No'},
            // { prop: 'id', label: '상품번호' },
            { prop: 'id', label: this.$t('luckdraw.prizeno') },

            // {prop: 'name',label: 'Contents'},
            // { prop: 'name', label: '내용물' },
            { prop: 'name', label: this.$t('luckdraw.contents') },

            // {prop: 'excode',label: 'Redemption code'},
            // { prop: 'excode', label: '상환 코드' },
            { prop: 'excode', label: this.$t('luckdraw.redemptioncode') },

            { prop: 'status', label: 'Status', formatter: row => this.statusState(row) },
            // {prop: 'exchangetime',label: 'Exchange date'},
            // { prop: 'exchangetime', label: '교환 날짜' },
            { prop: 'exchangetime', label: this.$t('luckdraw.exchangedate') },

            // {prop: 'createTime',label: 'createTime'},
            // { prop: 'createTime', label: '생성시간' },
            { prop: 'createTime', label: this.$t('luckdraw.createtime') },
        ]
        return {
            activeName: 'first',
            show: true,
            prizepools: [],
            columns,
            statusOptions,
            tableConfig: {
                url: '',
                dataPath: 'data.list',
                totalPath: 'data.total',
                paginationSize: 10,
                extraButtons: [
                    {
                        type: 'primary',
                        // text: 'exchange',
                        // text: '환전',
                        text: this.$t('luckdraw.exchange'),
                        icon: 'el-icon-folder-checked',
                        disabled: (row) => {
                            if (row.status == '1') {
                                return false
                            } else {
                                return true
                            }
                        },
                        atClick: async row => {
                            this.handleExchange(row)
                            return false
                        },
                    },
                ],
                headerButtons: [],
                hasDelete: false,
                hasEdit: false,
                hasOperation: true,
                hasNew: false,
                operationAttrs: {
                    width: '100px',
                    fixed: 'right',
                },
            },
            userinfo: {},
            integral: "",
        }
    },
    methods: {
        statusState(row) {
            let data
            this.statusOptions.forEach(r => {
                if (r.value == row.status) {
                    data = r.label
                }
            })
            return data
        },
        handleClick(tab) {
            console.log(tab.name);
            if (tab.name == 'second') {
                this.tableConfig.axiosConfig = {
                    headers: {
                        'Authorization': 'X-KILOGOD-AUTHORIZATION ' + this.$cookies.get('token'),
                    }
                }
                this.tableConfig.url = "userexcode/getByUserId?remark=来源抽奖"
                this.$refs.table.getList()
            }
        },
        openprize(num) {
            if (this.userinfo.integral < 10) {
                // MessageError("Insufficient points!")
                // MessageError("불충분한 포인트!")
                MessageError(this.$t('luckdraw.insufficientpoints'))
            }
            // this.$confirmel('This operation will consume 10 points. Continue?', 'Tips', {
            this.$confirmel(this.$t('luckdraw.consumepoints'), this.$t('luckdraw.tips'), {
                // confirmButtonText: 'comfirm',
                confirmButtonText: this.$t('luckdraw.comfirm'),
                // cancelButtonText: 'cancel',
                cancelButtonText: this.$t('luckdraw.cancel'),
                type: 'warning'
            }).then(() => {
                this.luckdrawInfo(num)
            }).catch(() => {
                // MessageInfo("Canceled");
                MessageInfo(this.$t('luckdraw.cancel'));
            });
        },
        async luckdrawInfo(num) {
            console.log(num)
            let res = await toluckdraw(num)
            if (res.status == "200") {
                this.prizepools = res.data.prizepools
                this.show = false
                this.getUserIntegralInfo()
                if (res.data.isprize == '0') {
                    MessageInfo(this.$t('luckdraw.failtowin'))
                } else {
                    MessageSuccess(this.$t('luckdraw.congratulations') +" "+res.data.prizepool.name + res.data.prizepool.timelength)
                }
            } else {
                MessageError(res.msg)
            }
        },
        copyexcode(val) {
            this.$copyText(val).then(
                function (e) {
                    console.log("copy arguments e:", e);
                    MessageSuccess('Copied successfully!');
                },
            );
        },
        async handleExchange(row) {
            let data = {
                id: row.id,
                excode: row.excode,
                userid: this.userinfo.id
            }
            let loading = startLoading()
            let res = await exchange(data)
            if (res.status == "200") {
                loading.close()
                MessageSuccess(res.msg)
            } else {
                loading.close()
                MessageError(res.msg)
            }
            loading.close()
            this.$refs.table.getList()
        },
        async getUserIntegralInfo() {
            let res = await getUserIntegral()
            if (res.status == "200") {
                this.integral = res.data
            } else {
                MessageError(res.msg)
            }
        }
    },
    created() {
        let userInfo = getUserInfo()
        if (userInfo) {
            this.userinfo = JSON.parse(userInfo)
            this.getUserIntegralInfo()
        }
    }
}
</script>

<style scoped>
.tabs {
    width: 96%;
    background-color: #ffffff;
    margin: 30px;
    border-radius: 5px;
}

/deep/ .el-tabs__header {
    margin-bottom: 0px;
}

/deep/ .el-tabs__item {
    font-weight: bolder;
}

.transition-common{
    display: inline-block;
    width: 80%;
    height: 302px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 40px;
}

.transition-box {
    background-image: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/luckdraw/slices.png);

}

.transition-boxopen {
    background-image: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/luckdraw/slices-open.png);
}

.coupontitle{
    font-size: 1.5em;
    font-weight: bolder
}
.couponinfo {
    width: 40%;
    height: 40px;
    background-image: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/luckdraw/coupon.png);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}
.couptxt{
    line-height: 35px;
    color: white;
}
.redemptioncode{
    font-family: Microsoft YaHei;
    font-weight: bolder;
    color: #1B1F24;
    font-size: 0.5em;
    margin-top: 40%;
    padding: 0 5px;
}
.no-data {
    padding: 32px 0;
    text-align: center;
    font-weight: 600;
}

.tip {
    padding-bottom: 16px;
}

.excode {
    color: #656C68;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 2px;
}

@media screen and (max-width: 768px){
    .tabs {
        width: 98%;
        background-color: #ffffff;
        margin-left: 1%;
        border-radius: 5px;
    }
    .el-message-box{
        width: 300px !important;
    }
    .el-message{
        width: 300px !important;
    }
    .transition-common{
        display: inline-block;
        width: 80%;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 20px;
    }
    .coupontitle{
        font-size: 0.8em;
        font-weight: bolder
    }
    .couponinfo {
        width: 60%;
        height: 35px;
        background-image: url(https://nfxbus.oss-us-west-1.aliyuncs.com/frontend/luckdraw/coupon.png);
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
    }
    .couptxt{
        line-height: 20px;
        color: white;
        font-size: 0.5em;
    }
    .redemptioncode{
        font-family: Microsoft YaHei;
        font-weight: bolder;
        color: #1B1F24;
        font-size: 0.5em;
        margin-top: 20%;
        padding: 0 5px;
    }
}
</style>
